import { CONFESSION_LIST, GET_DASBOARD,DELETE_NOTIFY, REPORT_LIST, GET_ALL_DRIVERS, GET_ALL_USERS, UPDATE_DRIVER_PROFILE, VIEW_DRIVER_DETAIL, VIEW_USER_DETAIL, GET_ALL_INTEREST,  DELETE_LIST, UPDATE_PROFILE_DETAIL, RECENT_JOIN_LIST, SUPPORT_LIST, BLOCK_LIST, NOTIFY_LIST, INT_ALL_LIST} from "../Constant/constant";

let initialstate = {
    UserList_Admin:null,
    totalUser:null,
    driverList:null,
    totalDriver:null,
    UserDetails:null,
    driverDetail:null,
    Admin_Interest_List:null,
    Deleteinterest:null,
    Dashboard:[],
    ResentJoin:null,
    SupportList:null,
    BlockListUser:null,
    ReportList:null,
    AdminNotificationList:null,
    Confession_List:null,
    Interest_List:null,
};
const AdminReducer = (state = initialstate, action) => {
  switch (action.type) {
    case NOTIFY_LIST:
      return{
        ...state,
        AdminNotificationList:action?.payload
      }
      case INT_ALL_LIST:
        return{
          ...state,
          Interest_List:action?.payload
        }
      case DELETE_NOTIFY:
        const deletenotify = state.NotificationDelete.map(
          (NotificationDelete) =>NotificationDelete?._id == action?.payload._id?action?.payload:NotificationDelete
        );
        return {
          ...state,
          Deleteinterest: deletenotify
        };
        case CONFESSION_LIST:
          return{
            ...state,
            Confession_List:action?.payload
          }
        case BLOCK_LIST:
          return{
            ...state,
            BlockListUser:action?.payload,
          }
      case REPORT_LIST:
        return{
          ...state,
          ReportList:action?.payload,
        }
        
    case GET_DASBOARD:
      return{
        ...state,
        Dashboard:action?.payload,
      }
      case RECENT_JOIN_LIST:
        return{
          ...state,
          ResentJoin:action?.payload,
        }
    case GET_ALL_USERS:
      return {
        ...state,
        UserList_Admin: action?.payload,
      };
      case UPDATE_PROFILE_DETAIL:
        const newArrup = state.UserList_Admin.map(
          (UserList_Admin) =>UserList_Admin?._id == action?.payload._id?action?.payload:UserList_Admin
        );
      return {
        ...state,
        UserList_Admin: newArrup
      };
      case GET_ALL_INTEREST:
        return {
          ...state,
          Admin_Interest_List: action?.payload,
        };
        case DELETE_LIST:
          const deleteArr = state.Deleteinterest.map(
            (Deleteinterest) =>Deleteinterest?._id == action?.payload._id?action?.payload:Deleteinterest
          );
          return {
            ...state,
            Deleteinterest: deleteArr
          };
      case GET_ALL_DRIVERS:
        return {
          ...state,
          driverList: action?.payload?.customer,
          totalDriver: action?.payload?.totalCustomer,
        };
        case VIEW_USER_DETAIL:
          return {
            ...state,
            UserDetails: action?.payload
          };

          case VIEW_DRIVER_DETAIL:
            return {
              ...state,
              driverDetail: action?.payload
            };
            case UPDATE_DRIVER_PROFILE:
              const newArr = state.driverList.map(
                (driverList) =>driverList?._id == action?.payload._id?action?.payload:driverList
              );
            return {
              ...state,
              driverList: newArr
            };

            case SUPPORT_LIST:
              return{
                ...state,
                SupportList: action?.payload
              }
    default:
      return state;
  }
};
export default AdminReducer;