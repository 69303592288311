import React,{useEffect, useState} from 'react';
import ProjectStatusBlogDonutChart from './ProjectStatusBlogDonutChart';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';


const ProjectStatusBlog = ({title,userDetail}) => {
    const [globalSelect, setGlobalSelect] = useState('Today');


    return (
        <>
            <div className="card">
                <div className="card-header pb-0 border-0">
                    <h4 className="heading mb-0">{title}</h4>                    
                    {/* <Dropdown className='global-drop'>
                        <Dropdown.Toggle as="div" className='i-false global-drop-toggle'>                            
                            {globalSelect}{" "}
                            <i className="fa-solid fa-chevron-down" /> 
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='global-drop-menu'>
                            <Dropdown.Item onClick={()=>setGlobalSelect('Today')}>Today</Dropdown.Item>
                            <Dropdown.Item onClick={()=>setGlobalSelect('Week')}>Week</Dropdown.Item>
                            <Dropdown.Item onClick={()=>setGlobalSelect('Month')}>Month</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                </div>
                <div className="card-body ">
                    
                    <ProjectStatusBlogDonutChart />
                    <div className="project-date">
                            <div className="">
                                <p className="mb-0">
                                    <svg className="me-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="12" height="12" rx="3" fill="var(--primary)"/>
                                    </svg>{" "}
                                    iOS
                                </p>
                                <div className="progress">
                                            <div className="progress-bar bg-primary" style={{width:"200px", height:"5px", borderRadius:"4px"}} ></div>
                                        </div>
                                <span>{userDetail?.iosregistration} Registered</span>
                            </div>	

                            <div className="mt-3">
                                <p className="mb-0">
                                    <svg className="me-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="12" height="12" rx="3" fill="#3AC977"/>
                                    </svg>{" "}
                                    Android
                                </p>
                                <div className="progress">
                                            <div className="progress-bar" style={{width:"200px", height:"5px", borderRadius:"4px", backgroundColor:"#3AC977"}} ></div>
                                        </div>
                                <span>{userDetail?.androidregistration} Registered</span>
                            </div>	


                    </div>
                </div>
            </div>  
        </>
    );
};

export default ProjectStatusBlog;