import React, { useEffect } from "react"
import Layout from "../components/layouts/Layout"
import MainPagetitle from "../components/layouts/MainPagetitle"
import { useDispatch, useSelector } from "react-redux"
import { SupportList } from "../../store/actions/AdminAction"


export default function Support(){

    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(SupportList())
    },[])

    const supportdata = useSelector((state) => state?.adminReducer?.SupportList)

    // console.log(supportdata,"sss")

    return(
        <Layout>
            <MainPagetitle mainTitle="Support" pageTitle="Support"  parentTitle="Home" /> 
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects manage-client">  
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Support</h4>
                                    </div> 
                                    <div id="report-tblwrapper" className="dataTables_wrapper no-footer">
                                        <table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                        <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Country</th>
                                                    <th>Phone Number</th>
                                                    <th>Custom Message</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                supportdata?.map((item,index)=>(
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.user_id?.full_name}</td>
                                                        <td>{item?.user_id?.email}</td>
                                                        <td>{item?.user_id?.country_name}</td>
                                                        <td>{item?.user_id?.phone_number}</td>
                                                        <td>{item?.description}</td>
                                                    </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}