import API from '../../services/Api';
import { toast } from 'react-toastify';
import Cookies from "universal-cookie";
// import { LOADING_TOGGLE_ACTION, LOGOUT_ACTION } from '../Constant/constant';
export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';
var cookies = new Cookies();


export function Logout(navigate) {
	localStorage.removeItem('userDetails');
  cookies.remove("adminToken");
  cookies.remove("name");
  cookies.remove("email");
    localStorage.clear("adminToken")
    navigate('/');
	//history.push('/login');
    
	return {
        type: LOGOUT_ACTION,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export const navtoggle = () => {
    return {        
      type: 'NAVTOGGLE',
    };
};
export const Adminlogin = (data,navigate,keepLogin) => async (dispatch) => {
    try {
      const response = await API.post("/AdminLogin", data);
      console.log(response,"adminlogin")

     if ( response?.data?.status == 200) {
        localStorage.setItem("adminToken", response.data.data.token);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem("email", response.data.data.email);
        window.location.href = "/dashboard";
      }
       else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  export const changePasswordAdmin = (data) => async () => {
    const res = await API.post("/AdminChangePassword", data);
    if (res?.data?.status == 200 ) {
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  };
  
 