import Layout from "../components/layouts/Layout";
import React, { useCallback, useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
/// Image
import uploadImg from "../../images/profile/profile-placeholder.png"
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  changePasswordAcc, deleteUserAcc, updateUserProfile, viewUserDetail, deactiveUserAcc, userInterset} from "../../store/actions/AdminAction";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import Buttons from 'react-bootstrap/Button';
import Placholder from "../../images/placholder-img-admin1.png";
import Form from 'react-bootstrap/Form';
import { ConfessionList } from "../../store/actions/AdminAction";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Gallery from "./Gallery";


const UserDetail = () => {

  const [show, setShow] = useState(false);
  const [showdeactive, setShowdeactive] = useState(false);
  const handleClose = () => setShow(false);
  const handleClosedeactive = () => setShowdeactive(false);
  const handleShow = () => setShow(true);
  const handleShowdeactive = () => setShowdeactive(true);

  // const IMG_URL=process.env.REACT_APP_IMAGE_URL
  const [profileImage, setProfileImage] = useState("");
  const [preview, setPreview] = useState("");
  const IMG_URL=process.env.REACT_APP_FILE_BASE_URL


  const [full_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberold, setPhoneNumberold] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [status, setStatus] = useState();
  const [password,setPassword]=useState()
  const [confirmPassword,setConfirmPassword]=useState()
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const userDetail = useSelector((state) => state?.adminReducer?.UserDetails);
  // console.log(userDetail, "profile list")

  const confession = useSelector((state) => state?.adminReducer?.Confession_List)
  const confessioncat = confession?.filter(item => userDetail?.confession_categories?.includes(item?._id))

  const interestlist=useSelector((state)=> state.adminReducer?.Interest_List)
  var interestname_array = interestlist?.filter(item => userDetail?.interest_in?.includes((item?._id)))



  // console.log("filteredInterest:", interestlist);



  useEffect(() => {
    dispatch(viewUserDetail(id));
    dispatch(userInterset());
    dispatch(ConfessionList())
  }, []);

  useEffect(() => {
    if (userDetail) {
      setProfileImage(userDetail?.main_image);
      setFirstname(userDetail?.full_name);
      // setLastname(userDetail?.last_name);
      setEmail(userDetail?.email);
      setPhoneNumber(userDetail?.phone_number );
      setPhoneNumberold(userDetail?.phone_number );
      setCountryCode(userDetail?.country_code );
      setStatus(userDetail?.status);
    }
  }, [userDetail]);


if(phoneNumber==phoneNumberold){
  var concatenatedNumber = `${countryCode !== undefined ? countryCode : ""} ${phoneNumber !== undefined ? phoneNumber : ""}`.trim() || "N/A";
}

  

console.log(phoneNumber);
 

  
  const handleSubmit = (e) => {

    const formData = new FormData();
    formData.append("id",userDetail?._id)
    profileImage && formData.append("main_image", profileImage);
    full_name && formData.append("full_name",full_name);
    email && formData.append("email", email);
     phoneNumber && formData.append("phone_number", phoneNumber.replace(/^(\+44|\+91)/, ""));
     countryCode && formData.append("country_code", countryCode);

    status && formData.append("status", status);
    dispatch(updateUserProfile(formData));
  };
  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };
 
const handleChangePass=()=>{
  const dataVal ={
    id:userDetail?._id,
    new_password: password
  }
  if(password){
    if(password==confirmPassword){
      dispatch(changePasswordAcc(dataVal))
    }else{
      toast.error("Password dosen't match")
    }
  }
}


    
  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="User Details & Edit"
          pageTitle={"User Details"}
          parentTitle={"Home"}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    {/* <div className="cover-photo rounded"></div> */}
                  </div>
                  <div className="profile-info user-profile-res">
                    <div className="user_detail_img_inner ">
                      { preview ? (
                        <img
                          src={
                            process.env.REACT_APP_FILE_BASE_URL + "user_document/" + userDetail?.main_image
                          }
                          className=" rounded-circle profile-image"
                          alt="profile"
                        /> ) : profileImage ?  (
                        <img
                          src={
                            process.env.REACT_APP_FILE_BASE_URL + "user_document/" + userDetail?.main_image
                          }
                          className=" rounded-circle profile-image"
                          alt="profile"
                        />
                      ) :  (
                        <img
                          src={uploadImg}
                          className=" rounded-circle profile-image"
                          alt="profile"
                        />
                      )
                      }
                    </div>
                    <input
                      className="user_detail_upload"
                      id="formFileSm"
                      type="file"
                      onChange={(e) => handleImageUpload(e)}
                      accept="image/*"
                    />
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label>First Name</label>
                              <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="1234 Main St"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={full_name}
                              />
                            </div>
                        
                           
                            <div className="form-group mb-3 col-md-6">
                              <label>Email</label>
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="1234 Main St"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Phone Number</label>
                              <input
                                type="text"
                                name="phone_number"
                                className="form-control"
                                placeholder="Phone Number"
                                onChange={(e) => setPhoneNumber(e.target.value )}
                                value={concatenatedNumber}
                              />
                            </div>
                    <div className="form-group mb-3 col-md-6">
                              <label>Status</label>
                              <select
                                defaultValue={"option"}
                                id="inputState"
                                className="form-control"
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="option" disabled hidden>
                                {userDetail?.status == 0 ? (
                                <span className="shadow-none badge badge-primary">
                                  Active
                                </span>
                              ) : userDetail?.status == 1 ? (
                                <span className="shadow-none badge badge-primary">
                                  Temporarily Banned
                                </span>
                              ) : (
                                <span className="shadow-none badge badge-primary">
                                  Banned
                                </span>
                              )}
                                </option>
                                <option value="1">Active</option>
                                <option value="0">Suspended</option>
                              </select>
                            </div>
                          </div>
                         <div>
                         <button
                            onClick={() => handleSubmit()}
                            className="btn btn-primary"
                          >
                            Save
                          </button>
                         </div>
                        </form>
                      </div>
                    </div>
                  </div>
                 <div className="user_additional_info_content pb-4">
                  <h4 className="card-title ">About Us</h4>
                    <hr/>
                   <p>{userDetail?.about_me}</p>
                   <h4 className="card-title mt-5">Confession</h4>
                    <hr/>
                    {
                      confessioncat?.map((item,i) => {
                        return(
                          <ul className="user_additional_info-new">
                          <li>
                          <div className="user_additional_info_inner">
                          <ArrowRightIcon/>
                        </div>
                            <div className="user_additional_info_content">
                            <h2>{item.name}</h2>
                            </div>
                          </li> 
                      </ul>
                        );
                      })
                    }
                   </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                    <div className="photo-content ">
                       <h4 className="card-title">Gallery</h4>
                       <div className="row">
                           <Gallery  images={userDetail?.multiple_image}  />
                       </div>
                      </div>
                  </div>
              </div>
            </div>

          </div>{" "}



          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Other Information</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                      <ul className="user_additional_info">
                
                          <li>
                            <div className="user_additional_info_inner">
                              {/* <img  src={profile} alt="" /> */}
                              <Button className="theme-change-btn"><PersonIcon /></Button>
                            </div>
                            <div className="user_additional_info_content">
                              <p>Profession</p>
                              <h2>{userDetail?.profession}</h2>
                            </div>
                          </li>
                        
                          <li>
                            <div className="user_additional_info_inner">
                              <Button className="theme-change-btn"><VolunteerActivismIcon /></Button>
                            </div>
                            <div className="user_additional_info_content">
                              <p>Marital Status</p>
                              <h2>{userDetail?.marital_status}</h2>

                            </div>
                          </li>
                          <li>
                            <div className="user_additional_info_inner">
                              <Button className="theme-change-btn"><LocationOnIcon /></Button>
                            </div>
                            <div className="user_additional_info_content">
                              <p>Address</p>
                              <h2>{userDetail?.address}</h2>
                            </div>
                          </li>
                          <li>
                            <div className="user_additional_info_inner">
                              <Button className="theme-change-btn"><PermIdentityIcon /></Button>
                            </div>
                            <div className="user_additional_info_content">
                              <p>Gender</p>
                              <h2>{userDetail?.gender==1?"Male":userDetail?.gender==2?"Female":""}</h2>
                            </div>
                          </li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-xl-6 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Interest</h4>
                </div>
                <div className="profile-interest-list-new pt-2 px-2">
                {
                    interestname_array?.map((item,i) => {
                return ( 
                            <div className="basic-form w-50">
                                  <ul className="user_additional_info-new">
                                      <li>
                                      <div className="user_additional_info_inner">
                                       <img
                                      src={item?.icon? IMG_URL + "Icon/" + item?.icon :Placholder}
                                      
                                      className="avatar avatar-md"
                                      alt=""
                                    />
                                    </div>
                                        <div className="user_additional_info_content">
                                        <h2>{item.name}</h2>
                                        </div>
                                      </li> 
                                  </ul>
                              </div>

                            );
                        })} 
                </div>

              </div>
            </div>

    
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-6 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Deactivate Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form">
                        <h6>
                          You will not be able to receive messages,
                          notifications for up to 24 hours.
                        </h6>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <label className="sr-only">Name</label>
                          </div>
                          <div className="col-xl-4 col-xxl-6 col-6">
                            <div className="form-check custom-checkbox mb-3">
                            <Form>
                              <Form.Check 
                                type="switch"
                                id="custom-switch"
                                onClick={handleShowdeactive}
                              />
                            </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-8">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Delete Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form">
                        <h6>
                          Once you delete the account, there is no going back.
                          Please be certain.
                        </h6>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <label className="sr-only">Name</label>
                          </div>
                          <div className="col-12">
                            <div className="form-check custom-checkbox mb-3 checkbox-success">
                              <button
                                className="btn btn-danger mb-2" onClick={handleShow}
                              >
                                Delete my account
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Reset Password</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label>New Password</label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                                value={password}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label>Confirm Password</label>
                          <input
                            type="password"
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                                value={confirmPassword}
                          />
                        </div>
                      </div>
                      <div className="row"></div>
                      <div className="form-group">
                        <div className=" "></div>
                      </div>
                      <button onClick={()=>{handleChangePass()}} className="btn btn-primary">
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Model */}
      <Modal show={show} onHide={handleClose} className="profile-delete-acc">
        <Modal.Header>
        <Modal.Title className="px-5 text-center">Are you sure you want to delete this account?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
        <Buttons variant="primary" onClick={()=>dispatch(deleteUserAcc(userDetail?._id,navigate))} >
             Yes
          </Buttons>
          <Buttons variant="primary" onClick={handleClose}>
            No
          </Buttons>
        </Modal.Footer>
      </Modal>
      {/* End Delete Model */}

       {/* Deactive Model */}
        <Modal show={showdeactive} onHide={handleClosedeactive} className="profile-delete-acc">
        <Modal.Header>
        <Modal.Title className="px-5 text-center">Are you sure you want to deactive this account?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
        <Buttons variant="primary"   onClick={()=>{
                dispatch(deactiveUserAcc({_id:userDetail?._id,status:userDetail.status==0?1:0}))           
                setShowdeactive(false)
                
                
                }} >
             Yes
          </Buttons>
          <Buttons variant="primary" onClick={handleClosedeactive}>
            No
          </Buttons>
        </Modal.Footer>
      </Modal>
      {/* End Deactive Model */}

    </Layout>
  );
};

export default UserDetail;
