import React, { useEffect, useState } from "react"
import Layout from "../components/layouts/Layout"
import MainPagetitle from "../components/layouts/MainPagetitle"
import Search from "../components/elements/Search"
import { useDispatch, useSelector } from "react-redux";
import { BlocktList } from "../../store/actions/AdminAction";
import moment from "moment";
import { navigate, useNavigate } from "react-router-dom";
import { bannedBlock } from "../../store/actions/AdminAction";
import Dropdown from 'react-bootstrap/Dropdown';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Link } from "react-router-dom";

export default function Block(){
const Blockdata = useSelector((state) => state?.adminReducer?.BlockListUser)
const[search, setSearch] = useState();
const limit = 10;
const[page, setPage] = useState(1);
const navigate = useNavigate()

const lastIndex = page * limit;
const npage = Math.ceil((Blockdata?.length==undefined?1:Blockdata?.length)/limit);
const number = [...Array(npage + 1).keys()].slice(1);

function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }


const dispatch = useDispatch();
useEffect (()=>{
    dispatch(BlocktList(page, limit, search))
},[page, limit, search])



const bannedblockstatus = (status, id) =>{
    dispatch(bannedBlock({status:status, id:id }));
    // dispatch(BlocktList());
  }

console.log(Blockdata,"blocklist")

    return(
        <Layout>
         <MainPagetitle mainTitle="Block Reason" pageTitle="Block Reason"  parentTitle="Home" /> 
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects manage-client">  
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Block Reasons</h4>
                                        <div className="mb-3"><Search  setSearch={setSearch}  setPage={setPage}/></div>
                                    </div> 
                                    <div id="report-tblwrapper" className="dataTables_wrapper no-footer">
                                        <table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                        <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Block By</th>
                                                    <th>Block To</th>
                                                    <th>Date & Time</th>
                                                    <th>Reason</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Blockdata?.map((item,index)=>(
                                                        <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                        <div className="pointerUser" onClick={() =>navigate(`/user-detail/${item?.block_by_details?._id}`)}>
                                                            {item?.block_by_details?.full_name}
                                                        </div>
                                                        </td>
                                                        <td>
                                                        <div className="pointerUser" onClick={() =>navigate(`/user-detail/${item?.block_to_detail?._id}`)}>
                                                            {item?.block_to_detail?.full_name}
                                                        </div>
                                                        </td>
                                                        <td>{moment(item?.createdAt).format("MM-DD-YYYY") + " & " + moment(item?.createdAt).format("HH:mm:ss")}</td>
                                                        <td>{item?.description}</td>
                                                        <td>
                                                        {item?.status == 0 ? (
                                                            <span className="shadow-none badge badge-primary">
                                                            Active
                                                            </span>
                                                        ) : item?.status == 2 ? (
                                                            <span className="shadow-none badge badge-primary">
                                                            Temporarily Banned
                                                            </span>
                                                        ) : (
                                                            <span className="shadow-none badge badge-primary">
                                                            Banned
                                                            </span>
                                                        )}

                                                        </td>
                                                        <td>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="border-0" variant="" id="dropdown-basic">
                                                             <MoreHorizOutlinedIcon/>
                                                        </Dropdown.Toggle>
                                                            
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item   onClick={()=>{
                                                                dispatch(bannedBlock({id:item?._id,status:0}))
                                                                }}>Active</Dropdown.Item>
                                                                <Dropdown.Item  onClick={()=>{
                                                                dispatch(bannedBlock({id:item?._id,status:2}))
                                                                }}>Temporarily Banned</Dropdown.Item>
                                                                <Dropdown.Item  onClick={()=>{
                                                                dispatch(bannedBlock({id:item?._id,status:3}))
                                                                }}> Banned</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>
                                                    </tr>

                                                   ))
                                                } 

                                            </tbody>
                                        </table>
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                        <div className="dataTables_info">
                                            Showing {lastIndex - limit + 1} to{" "}
                                            {Blockdata?.length==undefined?1:Blockdata?.length  < lastIndex
                                            ?Blockdata?.length==undefined?1:Blockdata?.length
                                            : lastIndex}{" "}
                                            of {Blockdata?.length==undefined?1:Blockdata?.length} Entries
                                        </div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers justify-content-center"
                                            id="example2_paginate"
                                        >
                                            <Link
                                            className="paginate_button previous disabled"
                                            to="#"
                                            onClick={prePage}
                                            >
                                            <i className="fa-solid fa-angle-left" />
                                            </Link>
                                            <span>
                                            {number.map((n, i) => (
                                                <Link
                                                className={`paginate_button ${
                                                    page === n ? "current" : ""
                                                } `}
                                                key={i}
                                                onClick={() => changeCPage(n)}
                                                >
                                                {n}
                                                </Link>
                                            ))}
                                            </span>
                                            <Link
                                            className="paginate_button next"
                                            to="#"
                                            onClick={nextPage}
                                            >
                                            <i className="fa-solid fa-angle-right" />
                                            </Link>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}