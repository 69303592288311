import React,{useContext, useEffect} from 'react';
import Layout from "../components/layouts/Layout";
import MainPagetitle from '../components/layouts/MainPagetitle';
import CardWidget from '../components/elements/CardWidget';
import ToDoList from '../components/elements/ToDoList';
import ProjectStatusBlog from '../components/elements/ProjectStatusBlog';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboard } from '../../store/actions/AdminAction';
import EarningChart from '../components/elements/EarningChart';


const Dashboard = () =>{

    const dispatch=useDispatch()
    useEffect(()=>{
        dispatch(getDashboard())
    },[])
    const userDetail = useSelector((state) => state?.adminReducer?.Dashboard);

    console.log(userDetail,"Dasboard data")
    return (
        <>	
        <Layout>
        <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-9 wid-100">
                    <div className="row">
                        <CardWidget userDetail={userDetail} />
                        <div className="col-xl-8">
                        <div className="card px-4">
                            <div className="card-header border-0 pb-0 ">
                                <h4 className="heading mb-0">Monthly Registration</h4>
                            </div>                
                            <EarningChart  userDetail={userDetail} />
                        </div>
                        </div>
                        <div className="col-xl-4">
                            <ToDoList  userDetail={userDetail}/>
                        </div>

                    </div>
                </div>
                <div className="col-xl-3 t-earn">
                <ProjectStatusBlog userDetail={userDetail}  title="Platforms"/>

                {/* <ActiveUserMap userDetail={userDetail} />	 */}
                </div>
                {/* <div className="col-xl-6 active-p">
                    <ActiveProjects />
                </div> */}
                {/* <div className="col-xl-6 col-md-6 flag">
                    <ActiveUserMap />		
                </div> */}
                {/* <div className="col-xl-4 col-md-6 chat-map">
                    <ChatElementBlog />
                </div> */}
                {/* <div className="col-xl-5 bst-seller">
                <BestSellerTable />
                </div> */}
                {/* <div className="col-xl-3 col-md-6 up-shd">
                    <UpcomingBlog />
                </div> */}

                <div className="col-xl-3 col-md-6 up-shd">
                </div>
                {/* <div className="col-xl-9 bst-seller">
                    <EmployeesTableList />
                </div> */}
            </div>							
        </div>		
         </Layout>
    </>
    )
}

export default Dashboard;