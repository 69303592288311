import {  Route, Routes} from 'react-router-dom';
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Login from './jsx/pages/Login';
import Dashboard from './jsx/pages/Dashboard';
import { ToastContainer } from 'react-toastify';
import UserManagement from './jsx/pages/UserManagement';
import Report from './jsx/pages/Report';
import ManageAdmins from './jsx/pages/ManageAdmins';
import ChangePassword from './jsx/pages/ChangePassword';
import PrivateRoute from './jsx/routes/PrivateRoute';
import UserDetail from './jsx/pages/UserDetail';
import Drivers from './jsx/pages/Drivers';
import DriverDetail from './jsx/pages/DriverDetail';
import Intrests from './jsx/pages/Intrests';
import Block from './jsx/pages/Block';
import Support from './jsx/pages/Support';
import Notification from './jsx/pages/Notification';

function App () {
   
  return (
    <div className="vh-100">
    <ToastContainer limit={1}  autoClose={500}/>
      <Routes> 
        <Route path='/' element={<Login />} />
        <Route element={<PrivateRoute />}>
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/user-management' element={<UserManagement />} />
        <Route path='/interest' element={<Intrests />} />
        <Route path='/user-detail/:id' element={<UserDetail />} />
        <Route path='/report-reasons' element={<Report/>} />
        <Route path='/block-reasons' element={<Block/>} />
        <Route path='/support' element={<Support/>} />
        <Route path='/notification'  element={<Notification/>}/>
      </Route>   
      </Routes> 
    </div>
  );
};



export default App; 

