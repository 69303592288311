import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import the CSS for styling



export default function Gallery({images}) {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };
  return (
    <div className="profile-gallery-sec">
    {images?.map((image, index) => (
      <img
        key={index}
        src={ process.env.REACT_APP_FILE_BASE_URL +"user_document/"+  image}
        alt={`Image ${index + 1}`}
        onClick={() => openLightbox(index)}
        className='profile-gallery'
      />
    ))}
    {isOpen && (
      <Lightbox
        mainSrc={process.env.REACT_APP_FILE_BASE_URL +"user_document/"+ images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={closeLightbox}
        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
      />
    )}
  </div>
  )
}
