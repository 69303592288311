import Layout from '../components/layouts/Layout'
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import MainPagetitle from "../components/layouts/MainPagetitle";
import Search from '../components/elements/Search';
import { useDispatch, useSelector } from 'react-redux';
import { ReportList } from '../../store/actions/AdminAction';
import { navigate, useNavigate } from "react-router-dom";
import moment from 'moment';
import { BannedReport} from "../../store/actions/AdminAction";
import Dropdown from 'react-bootstrap/Dropdown';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';



const Report = () => {
    const Reportdata = useSelector((state) =>state?.adminReducer?.ReportList)

    const[search, setSearch] = useState();
    const limit = 10;
    const[page, setPage] = useState(1);
    const navigate = useNavigate()

    const lastIndex = page * limit;
    const npage = Math.ceil((Reportdata?.length==undefined?1:Reportdata?.length)/limit);
    const number = [...Array(npage + 1).keys()].slice(1);


    function prePage() {
        if (page !== 1) {
          setPage(page - 1);
        }
      }
      function changeCPage(id) {
        setPage(id);
      }
      function nextPage() {
        if (page !== npage) {
          setPage(page + 1);
        }
      }
    

    const dispatch = useDispatch()
    useEffect(() =>{
        dispatch(ReportList(page, limit, search));
    },[page, limit, search])


    console.log(Reportdata,'report list')

    const bannedstatus = (status, id) =>{
        dispatch(BannedReport({status:status, id:id }));
        // dispatch(ReportList());
      }

  return (
    <Layout>
         <MainPagetitle mainTitle="Reports" pageTitle="Report"  parentTitle="Home" /> 
            <div className='container-fluid'>
                <div className='row'>
                    <div className="col-xl-12">
                        <div className="card">                            
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects manage-client">   
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Report Reason</h4>
                                        <div className="mb-3"><Search  setSearch={setSearch}  setPage={setPage}/></div>
                                    </div>          
                                    <div id="report-tblwrapper" className="dataTables_wrapper no-footer">
                                        <table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Report by</th>
                                                    <th>Report To</th>
                                                    <th>Date & Time</th>
                                                    <th>Reason</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Reportdata?.map((item, index)=>(
                                                    <tr key={index}>
                                                        <td>
													       {index + 1}
                                                        </td>   
                                                        <td>
                                                        <div className="pointerUser" onClick={() =>navigate(`/user-detail/${item?.report_by_details?._id}`)}>
                                                            {item?.report_by_details?.full_name}
                                                        </div>
                                                        </td>
                                                        <td>
                                                        <div className="pointerUser" onClick={() =>navigate(`/user-detail/${item?.report_to_detail?._id}`)}>
                                                            {item?.report_to_detail?.full_name}
                                                        </div>
                                                        </td>
                                                        <td>
                                                            <span>{moment(item?.createdAt).format("MM-DD-YYYY") + " & " + moment(item?.createdAt).format("HH:mm:ss")}</span>
                                                        </td>	
                                                        <td>
                                                            <span className="text-secondary">{item.description} </span>
                                                        </td>
                                                        <td>
                                                        {item?.status == 0 ? (
                                                            <span className="shadow-none badge badge-primary">
                                                            Active
                                                            </span>
                                                        ) : item?.status == 1 ? (
                                                            <span className="shadow-none badge badge-primary">
                                                            Temporarily Banned
                                                            </span>
                                                        ) : (
                                                            <span className="shadow-none badge badge-primary">
                                                            Banned
                                                            </span>
                                                        )}
                                                        </td>
                                                        <td>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="border-0" variant="" id="dropdown-basic">
                                                             <MoreHorizOutlinedIcon/>
                                                        </Dropdown.Toggle>
                                                            
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item   onClick={()=>{
                                                                dispatch(BannedReport({id:item?._id,status:0}))
                                                                }}>Active</Dropdown.Item>
                                                                <Dropdown.Item  onClick={()=>{
                                                                dispatch(BannedReport({id:item?._id,status:1}))
                                                                }}>Temporarily Banned</Dropdown.Item>
                                                                <Dropdown.Item  onClick={()=>{
                                                                dispatch(BannedReport({id:item?._id,status:2}))
                                                                }}> Banned</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            
                                        </table>
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                        <div className="dataTables_info">
                                            Showing {lastIndex - limit + 1} to{" "}
                                            {Reportdata?.length==undefined?1:Reportdata?.length  < lastIndex
                                            ?Reportdata?.length==undefined?1:Reportdata?.length
                                            : lastIndex}{" "}
                                            of {Reportdata?.length==undefined?1:Reportdata?.length} Entries
                                        </div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers justify-content-center"
                                            id="example2_paginate"
                                        >
                                            <Link
                                            className="paginate_button previous disabled"
                                            to="#"
                                            onClick={prePage}
                                            >
                                            <i className="fa-solid fa-angle-left" />
                                            </Link>
                                            <span>
                                            {number.map((n, i) => (
                                                <Link
                                                className={`paginate_button ${
                                                    page === n ? "current" : ""
                                                } `}
                                                key={i}
                                                onClick={() => changeCPage(n)}
                                                >
                                                {n}
                                                </Link>
                                            ))}
                                            </span>
                                            <Link
                                            className="paginate_button next"
                                            to="#"
                                            onClick={nextPage}
                                            >
                                            <i className="fa-solid fa-angle-right" />
                                            </Link>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Layout>
  )
}

export default Report