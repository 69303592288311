import React from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getDashboard } from "../../../store/actions/AdminAction";
import { useSelector } from "react-redux";



export default function ProjectStatusBlogDonutChart(){
  const dispatch = useDispatch();
  useEffect(() =>{
    dispatch(getDashboard())
  },[])
  const DasboardData = useSelector((state) => state?.adminReducer?.Dashboard);

  var firstdata=DasboardData?.androidregistration;
  var seconddata=DasboardData?.iosregistration;
  // var thirddata=DasboardData?.platformstart;



  const data ={
   
  series: [firstdata==undefined?0:firstdata,seconddata==undefined?0:seconddata],
  options: {
    chart: {
  type: 'donut',
  width: 250,
},
    colors: ['#3ac977', 'var(--primary)', '#3AC977'],
    labels: ["Compete", "Pending", "Yet to Start"],
    dataLabels: {
        enabled: false,
    },      
    
    legend: {
      show: false,
    },
    plotOptions: {          
        pie: {
            donut: {
              size: '90%',
              labels: {
                  show: true,
                  name: {
                      show: true,
                      offsetY: 12,
                  },
                  value: {
                      show: true,
                      fontSize: '24px',
                      fontFamily:'Arial',
                      fontWeight:'500',
                      offsetY: -17,
                  },
                  total: {
                      show: true,
                      fontSize: '11px',
                      fontWeight:'500',
                      fontFamily:'Arial',
                      label: 'Total Singup',
                     
                      formatter: function (w) {
                        return w.globals.seriesTotals.reduce((a, b) => {
                          return a + b
                        }, 0)
                      }
                  }
              }
            }
        }

    },
   
  },
};

  return(
    <div id="projectChart" className="project-chart">
    <ReactApexChart
      options={data?.options}
      series={data?.series}
      type="donut"
      width={250}
    />
  </div>
  )
}



