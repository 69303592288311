import { toast } from "react-toastify";
import API from "../../services/Api";
import { CONFESSION_LIST,INT_ALL_LIST, GET_DASBOARD,NOTIFY_LIST,DELETE_NOTIFY,  REPORT_LIST,BLOCK_LIST, RECENT_JOIN_LIST, GET_ALL_DRIVERS, GET_ALL_USERS, UPDATE_DRIVER_PROFILE, UPDATE_PROFILE_DETAIL, VIEW_DRIVER_DETAIL, VIEW_USER_DETAIL, GET_ALL_INTEREST, DELETE_LIST, SUPPORT_LIST } from "../Constant/constant";


// Dasboard
export const getDashboard =(data) => async (dispatch) =>{
  try{
    const das = await API.get("/Dashboard",data);
    if(das.data.status == 200){
      dispatch({
        type: GET_DASBOARD,
        payload:das?.data?.data,
      });
    }else{
      toast.error(data?.message);
    } 
  }catch(error){
    toast.error(error);
  }
};

// Recent Join
export const Resentjoin =(data) => async (dispatch) =>{
  try{
    const recjoin = await API.get("/ResentJoin",data);
    if(recjoin.data.status == 200){
      dispatch({
        type: RECENT_JOIN_LIST,
        payload: recjoin?.data?.data,
      });
    }else{
      toast.error(data?.message)
    }
  }catch(error){
    toast.error(error);
  }
}

// Usermanagment list data
export const getAllUsers = (page, limit, search, data) => async (dispatch) => {
    try {
      const res = await API.get(
        `/UserList_Admin?page=${
          page == undefined ? 1 : page
        }&limit=${limit}&search=${search ? search : undefined}`,
        data
      );
      console.log(res, "userlist")
      if (res?.data?.status == 200) {
        dispatch({
          type: GET_ALL_USERS,
          payload: res?.data?.data,
        });
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };


// Ban Action in Usermanagmnet
   export const banuserAction = (data) => async (dispatch) => {
      try {
        const ban = await API.post("/Banned", data);
        if (ban.data.status == 200) {
          dispatch(getAllUsers());
        }
      } catch (error) {
        toast.warning(error.message);
      }
    };

// User Details
  export const viewUserDetail = (id) => async (dispatch) => {
    try {
      const { data } = await API.get(
        `/UserDetails?id=${id}`
      );
      if (data?.status == 200) {
        dispatch({
          type: VIEW_USER_DETAIL,
          payload: data.data,
        });
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // Update user profile
  export const updateUserProfile = (data) => async (dispatch) => {
    try {
      const res = await API.post("/AdminUpdate", data);
      if (res?.data.status == 200) {
        toast.success(res?.data?.message);
        dispatch({
          type: UPDATE_PROFILE_DETAIL,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };



  // Deactive userProfile Account
  export const deactiveUserAcc = (data) => async (dispatch) => {
    try {
      const response = await API.post(`/Activate_Deactivate`,data);
      if (response?.data?.status==200) {
        toast.success(response?.data?.message);
      } else {

        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };


  // Delete UserAccount 
    export const deleteUserAcc = (id,navigate) => async (dispatch) => {
      try {
        const response = await API.get(`/DeleteAccountAdmin?id=${id}`);
        if (response?.data?.status == 200) {
          toast.success(response?.data?.message);
          navigate("/user-management")
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error);
      }
    };

 // Change Password
  export const changePasswordAcc = (data) => async () => {
    const res = await API.post("/AdminResetPassword", data);
    if (res?.data.status == 200 ) {
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  };
  
  // Interest List
  export const getAllInterest = (page, limit, search, data) => async (dispatch) => {
    try {
      const resinterest = await API.get(
        `/Admin_Interest_List?page=${
          page == undefined ? 1 : page
        }&limit=${limit}&search=${search ? search : undefined}`,
        data
      );
      // console.log(res, "userlist")
      if (resinterest?.data?.status == 200) {
        dispatch({
          type: GET_ALL_INTEREST,
          payload: resinterest?.data?.data,
        });
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };


  // Userdetail Interest
  export const userInterset =(data) =>async(dispatch)=>{
    try{
      const intlist = await API.get("/Interest_List",data);
      if(intlist?.data?.status == 200){
        dispatch({
          type: INT_ALL_LIST,
          payload:intlist?.data?.data,
        });
      }else{
        toast.error(data?.message);
      }
    }catch(error){
      toast.error(error);
    }
  }
  
// Repost Reason list
export const ReportList = (page, limit, search, data) => async (dispatch) =>{
  try{
    const repres = await API.get(
      `/ReportList?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`,
      data
    );
    if(repres?.data.status == 200){
      // toast.success(repres?.data?.message);
      dispatch({
        type: REPORT_LIST,
        payload: repres?.data?.data,
      });
    }else{
      // toast.error(repres?.data?.message);
    }
  }catch(error){
    toast.error(error);
  }
}

 // Block List
 export const BlocktList = (page, limit, search, data) => async (dispatch) =>{
  try{
    const blockres = await API.get(
      `/BlockListUser?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`,
      data
    );
    if(blockres?.data.status == 200){
      // toast.success(blockres?.data?.message);
      dispatch({
        type: BLOCK_LIST,
        payload: blockres?.data?.data,
      });
    }else{
      toast.error(blockres?.data?.message);
    }
  }catch(error){
    toast.error(error);
  }
}


// Add Interest Action
export const AddinterestAction=(data)=>async(dispatch)=>{
    try {
      const saveinfo = await API.post("/Save_Interest",data);
      if (saveinfo?.data?.status == 200) {
        toast.success(saveinfo?.data?.message);
      } else {
        toast.error(saveinfo?.data?.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  }

    // Delete Interest List  
    export const Deleteinterest = (id) => async (dispatch) => {
      try {
        const response = await API.get(`/InterestDelete?id=${id}`);
        if (response?.data?.status == 200) {
          toast.success(response?.data?.message);
          dispatch({
            type: DELETE_LIST,
            payload: response?.data?.data,
          });
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error);
      }
    };


      // confession List
      export const ConfessionList = (data) => async (dispatch) =>{
        try{
          const conres = await API.get("/Confession_List",data);
          if(conres?.data.status == 200){
            // toast.success(suppres?.data?.message);
            dispatch({
              type: CONFESSION_LIST,
              payload: conres?.data?.data,
            });
          }else{
            toast.error(conres?.data?.message);
          }
        }catch(error){
          toast.error(error);
        }
      }

      
  
    // Support List
    export const SupportList = (data) => async (dispatch) =>{
      try{
        const suppres = await API.get("/SupportList",data);
        if(suppres?.data.status == 200){
          // toast.success(suppres?.data?.message);
          dispatch({
            type: SUPPORT_LIST,
            payload: suppres?.data?.data,
          });
        }else{
          toast.error(suppres?.data?.message);
        }
      }catch(error){
        toast.error(error);
      }
    }


// Block Banned Status
export const bannedBlock = (data) => async (dispatch) =>{
  try{
    const banblock = await API.post("/BlockBann",data);
    if(banblock.data.status == 200){
      dispatch(BlocktList());
    }
  }catch(error){
    toast.warning(error.message);
  }
}



// Report Banned Status
export const BannedReport = (data) => async (dispatch) =>{
  try{
    const banreport = await API.post("/ReportBannd",data);
    if(banreport.data.status == 200){
      dispatch(ReportList());
    }
  }catch(error){
    toast.warning(error.message);
  }
}

// Notification List
export const getNotify =(data) => async (dispatch) =>{
  try{
    const Notifylist = await API.get("/AdminNotificationList",data);
    if(Notifylist?.data?.status == 200){
      dispatch({
        type: NOTIFY_LIST,
        payload: Notifylist?.data?.data,
      });
    }else{
      toast.error(Notifylist?.data?.message);
    }
  }catch(error){
    toast.error(error);
  }
}

   // Add Notification 
   export const AddNotify = (data)=>async(dispatch)=>{
    try {
      const savenotify = await API.post("/AddNotification",data);
      if (savenotify?.data?.status == 200) {
        toast.success(savenotify?.data?.message);
      } else {
        toast.error(savenotify?.data?.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  }


  // Delete Notification List  
   export const Deletenotify = (id) => async (dispatch) => {
        try {
          const response = await API.get(`/NotificationDelete?id=${id}`);
          if (response?.data?.status == 200) {
            toast.success(response?.data?.message);
            dispatch({
              type: DELETE_NOTIFY,
              payload: response?.data?.data,
            });
          } else {
            toast.error(response?.data?.message);
          }
        } catch (error) {
          toast.error(error);
        }
      };
  


  export const driversList = (page, limit, search, data) => async (dispatch) => {
    try {
      const res = await API.get(
        `/getAlldrivers?page=${
          page == undefined ? 1 : page
        }&limit=${limit}&search=${search ? search : undefined}`,
        data
      );
      if (res?.data?.success) {
        dispatch({
          type: GET_ALL_DRIVERS,
          payload: res?.data?.data,
        });
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  export const viewDriverDetail = (id) => async (dispatch) => {
    try {
      const { data } = await API.get(
        `/getDriverProfileById?id=${id}`
      );
      if (data?.success) {
        dispatch({
          type: VIEW_DRIVER_DETAIL,
          payload: data.data,
        });
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  export const updateDriverProfile = (data) => async (dispatch) => {
    try {
      const res = await API.post("/updateDriverProfile", data);
      if (res?.data.success) {
        dispatch({
          type: UPDATE_DRIVER_PROFILE,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.message)
        dispatch(driversList())
      } else {
        toast.error(res?.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  export const deleteDriverAcc = (id,navigate) => async (dispatch) => {
    try {
      const response = await API.get(`/deleteDriverAccount?id=${id}`);
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        navigate("/drivers")
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  export const changePasswordDriver = (data) => async () => {
    const res = await API.put("/resetPassDriver", data);
    if (res?.data.success ) {
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  };


