import React from "react";
import Layout from "../components/layouts/Layout";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useState, useEffect, dispatch } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllInterest} from "../../store/actions/AdminAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Form from 'react-bootstrap/Form';
import { useFormik } from "formik";
import {AddinterestAction,} from '../../store/actions/AdminAction'
import { Link, useNavigate } from "react-router-dom";
import Search from "../components/elements/Search";
import Placholder from "../../images/placholder-img-admin1.png";
import { Deleteinterest } from "../../store/actions/AdminAction";



const Intrests = () => {
  const allInterest = useSelector((state) => state?.adminReducer?.Admin_Interest_List);
  console.log(allInterest, "interest list")
  const limit = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const lastIndex = page * limit;
  const npage = Math.ceil((allInterest?.totallength==undefined?1:allInterest?.totallength)/limit);
  const number = [...Array(npage + 1).keys()].slice(1);
  const IMG_URL=process.env.REACT_APP_FILE_BASE_URL

  const [show, setShow] = useState(false);
  const [showupdate, setShowupdate] = useState(false);
  const [deletevalue, setDeletevalue] = useState(false);
  const [idinterest, setIdinterest] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (object_interest) => {
      formik.setFieldValue('name',object_interest.name);
      formik.setFieldValue('icon',object_interest.icon);
      setShow(true)
      setIdinterest(object_interest?._id);
    };

    const handleShowdelete = () => setDeletevalue(true);
    const handleClosedelete = () => setDeletevalue(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllInterest(page, limit, search));
      },[page, limit, search]);

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
          errors.name = "Interest is Required*";
        }else if(!values.icon){
            errors.icon = "image is Required*";
        }
        return errors;
      };

    const formik = useFormik({
      initialValues: {
        name: "",
        icon: "",

      },
      validate,
      onSubmit: (values) => {
        setShowupdate(false)
            const appendformdata=new FormData();
            appendformdata.append('name',values.name);
            appendformdata.append('Icon',values.icon);
            appendformdata.append('id',idinterest)

        dispatch(AddinterestAction(appendformdata));
        setShow(false)
        dispatch(getAllInterest());
      },
    });

    function prePage() {
      if (page !== 1) {
        setPage(page - 1);
      }
    }
    function changeCPage(id) {
      setPage(id);
    }
    function nextPage() {
      if (page !== npage) {
        setPage(page + 1);
      }
    }


    
    return(
    <Layout>
   <div> <MainPagetitle mainTitle="Interests" pageTitle={"Interests"} parentTitle={"Home"} /></div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-auto float-end ms-auto mb-3">
              <Button  variant="primary" onClick={handleShow}>
                  <i className="fa fa-plus" /> Add Interest
              </Button>
          </div>
          <div className="col-xl-12">
            <div className="card mt-2">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                        <h4 className="heading mb-3">Interests</h4>
                        <div className="mb-3"><Search  setSearch={setSearch}  setPage={setPage}/></div>
                        <table
                            id="empoloyees-tblwrapper"
                            className="table ItemsCheckboxSec dataTable no-footer mb-0 interest-table"
                            >
                            <thead>
                                <tr>
                                <th>S.No</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                            {

                            allInterest?.listintrest?.map((item, index) =>(
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>   <img
                                  src={item?.icon? IMG_URL + "Icon/" + item?.icon :Placholder}
                                  className="avatar avatar-md"
                                  alt=""
                                /></td>
                                <td>{item?.name}</td>
                                <td className="icon-intrest"><BorderColorIcon variant="primary" onClick={()=>{handleShow(item)}} />
                                 <DeleteIcon variant="primary" onClick={() => {
                                   setIdinterest(item?._id);
                                  setDeletevalue(true);
                              }}/></td>
                            </tr>
                            )
                            )}
                      
                            </tbody>
                        </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center dataTables_wrapper interest-pagination"  id="employee-tbl_wrapper">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {allInterest?.totallength==undefined?1:allInterest?.totallength  < lastIndex
                          ?allInterest?.totallength==undefined?1:allInterest?.totallength
                          : lastIndex}{" "}
                        of {allInterest?.totallength==undefined?1:allInterest?.totallength} Entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>

                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
    </div>




    {/* Add Interests Modal */}

    <Modal show={show} onHide={handleClose}>
    <form className="modelform" onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Interest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
            <div className="form-group">
                <label> Name <span className="text-danger">*</span></label>
                <input className="form-control" type="text" name="name" placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                />
                {formik.errors.name && (
                    <div className="text-danger fs-12">
                    {formik.errors.name}
                </div>
                 )}
            </div>
            <div className="form-group mt-2">
                <label> Select Image <span className="text-danger">*</span></label>
                <input className="form-control" type="file" name="Icon" placeholder="Name"
               value={formik.values.Icon}
               onChange={
                (e)=>{
                    formik.setFieldValue('icon',e.target.files[0])                  
                }
              
                }
                />
                {formik.errors.icon && (
                    <div className="text-danger fs-12">
                    {formik.errors.icon}
                </div>
                 )}
            </div>
        
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary">
            Add
          </Button>
        </Modal.Footer>
        </form>
      </Modal>

    {/* /Add Interests Modal */}


     {/* Delete Interests Modal */}
      <Modal show={deletevalue} onHide={handleClosedelete} className="profile-delete-acc">
        <Modal.Header>
        <Modal.Title className="px-5 text-center">Are you sure you want to delete this interest?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
        <Button variant="primary" onClick={() => {
              dispatch(Deleteinterest(idinterest)).then(function(){
                dispatch(getAllInterest(page, limit, search));
              });
              setDeletevalue(false);
            }} >
             Yes
          </Button>
          <Button variant="primary" onClick={handleClosedelete}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    
    {/* /update Delete Modal */}
        
    </Layout>

    )
}


export default Intrests;