export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';
export const  GET_ALL_USERS="GET_ALL_USERS";
export const  GET_ALL_DRIVERS="GET_ALL_DRIVERS";
export const  VIEW_USER_DETAIL="VIEW_USER_DETAIL";
export const UPDATE_PROFILE_DETAIL="UPDATE_PROFILE_DETAIL"
export const UPDATE_DRIVER_PROFILE="UPDATE_DRIVER_PROFILE"
export const VIEW_DRIVER_DETAIL="VIEW_DRIVER_DETAIL"
export const GET_ALL_INTEREST = "GET_ALL_INTEREST"
export const DELETE_LIST = "DELETE_LIST"
export const GET_DASBOARD = "GET_DASBOARD"
export const RECENT_JOIN_LIST ="RECENT_JOIN_LIST"
export const SUPPORT_LIST = "SUPPORT_LIST"
export const BLOCK_LIST = "BLOCK_LIST"
export const REPORT_LIST = "REPORT_LIST"
export const NOTIFY_LIST = "NOTIFY_LIST"
export const DELETE_NOTIFY = "DELETE_NOTIFY"
export const CONFESSION_LIST = "CONFESSION_LIST"
export const INT_ALL_LIST = "INT_ALL_LIST"
