import React, { useEffect } from "react"
import Layout from "../components/layouts/Layout"
import MainPagetitle from "../components/layouts/MainPagetitle"
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useFormik } from "formik";
import { getNotify } from "../../store/actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import { AddNotify } from "../../store/actions/AdminAction";
import { Deletenotify } from "../../store/actions/AdminAction";


export default function Notification(){
    const [deletevalue, setDeletevalue] = useState(false);
    const handleClosedelete = () => setDeletevalue(false);
    const [id, setId] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const validate = (values) => {
        const errors = {};
        if (!values.gender) {
          errors.gender = "User is Required*";
        }else if(!values.title){
            errors.title = "Enter title*";
        }else if(!values.description){
            errors.description = "Write some content*";
        }
        return errors;
      };
      const formik = useFormik({
        initialValues: {
          gender: "",
          title: "",
          description: "",
  
        },
        validate,
        onSubmit: (values) => {
          dispatch(AddNotify(values));
          setShow(false)
          dispatch(getNotify());
        },
      });

    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getNotify());
    },[])

    const Notifydata = useSelector((state) => state?.adminReducer?.AdminNotificationList)
    console.log(Notifydata,'notification list')

    return(
        <Layout>
            <MainPagetitle mainTitle="Notification" pageTitle="Notification"  parentTitle="Home" /> 
            <div className="container-fluid">
                <div className="row">
                <div className="col-auto float-end ms-auto mb-2">
                            <Button  variant="primary" onClick={handleShow} >
                                <i className="fa fa-plus" /> Add Notification
                            </Button>
                        </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects manage-client">  
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Notification</h4>
                                    </div> 
                                    <div id="report-tblwrapper" className="dataTables_wrapper no-footer">
                                        <table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                        <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Description</th>
                                                    <th>Title</th>
                                                    <th>To</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                Notifydata?.map((item,index)=>(
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.title}</td>
                                                        <td>{item?.description}</td>
                                                        <td>
                                                            {/* {item?.gender == 1?"Male":item?.gender == 2?"Female":""} */}
                                                            {item.gender && item.gender == 1
                                                                ? "All Male"
                                                                : item.gender == 2
                                                                    ? "All Female"
                                                                    : item?.gender==3? "All User":""}
                                                        
                                                        </td>
                                                        <td className="icon-intrest">
                                                        <DeleteIcon variant="primary" onClick={() => {
                                                            setDeletevalue(true);
                                                            setId(item?._id);
                                                        }}/>
                                                    </td>
                                                    </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        {/* Add Notification Model */}
        <Modal show={show} onHide={handleClose}>
        <form className="modelform" onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
            <Modal.Title>Add Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="form-group mb-2">
                <label> Select Users <span className="text-danger">*</span></label>
             <Form.Select className="form-control-select" aria-label="Default select example" 
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange} 
                // onChange={(e) =>
                //     formik.setFieldValue("gender", e.target.value)
                //   }
                > 
                <option>Select Users</option>
                <option value="1">All Male</option>
                <option value="2">All Female</option>
                <option value="3">All Users</option>
                </Form.Select>
                {formik.errors.gender && (
                    <div className="text-danger fs-12">
                    {formik.errors.gender}
                </div>
                 )}
                
            </div>

            <div className="form-group">
                <label> Title <span className="text-danger">*</span></label>
                <input className="form-control" type="text" name="title" placeholder="Title"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.title && (
                    <div className="text-danger fs-12">
                    {formik.errors.title}
                </div>
                 )}
            </div>

            <div className="form-group mt-2">
                <label> Description <span className="text-danger">*</span></label>
                <textarea className="form-control" name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                {formik.errors.description && (
                    <div className="text-danger fs-12">
                    {formik.errors.description}
                </div>
                 )}
            </div>
   
            </Modal.Body>
            <Modal.Footer>
            <Button type="submit" variant="primary">
                Notify
            </Button>
            </Modal.Footer>
          </form>
        </Modal>
        {/* End Notification Model */}

     {/* Delete Interests Modal */}
      <Modal show={deletevalue} onHide={handleClosedelete} className="profile-delete-acc">
        <Modal.Header>
        <Modal.Title className="px-5 text-center">Are you sure you want to delete this notification?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
        <Button variant="primary" onClick={() => {
                    dispatch(Deletenotify(id)).then(function(){
                        dispatch(getNotify());
                    });
                    setDeletevalue(false);
                    }} >
             Yes
          </Button>
          <Button variant="primary" onClick={handleClosedelete}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    

        </Layout>
    )
}