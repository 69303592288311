import axios from 'axios';

const baseURL=process.env.REACT_APP_API_BASE_URL;
const API = axios.create({
  baseURL: baseURL,
  // timeout: 2000,
  headers: {
    authorization: localStorage.getItem('adminToken'),
  },
});
console.log(baseURL)
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      setTimeout(() => {
        localStorage.clear();
        window.location.reload(false);
        window.location.href = '/';
      }, 1000);
    }
     return error.response;
  }
);

export default API;
